<template lang="pug">
div
  h1 Bike Stats
  div(style="display: flex; padding: 1rem")
    //- chart(type="line", :data="data", :options="options")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import { Chart } from "vue-chart-js";
// import { Chart as ChartJS } from 'chart.js/auto'
// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  // components: { Chart },
  data() {
    return {
      options: {},
      data: {
        labels: [ 'January', 'February', 'March' ],
        datasets: [ { data: [40, 20, 12] } ]
      },
      chartOptions: {
        responsive: true
      }
    };
  },
  computed: {
    ...mapGetters({
      items: "bike/items",
    }),
  },
  methods: {
    ...mapActions({
      list: "bike/list",
    }),
  },
  async mounted() {
    await this.list({ person: "em", days: 1 });

  },
};
</script>

<style lang="less" scoped>
</style>