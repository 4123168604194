<template lang="pug">
.button-26.key(@click="click()", :class="{ disabled: !enabled }")
  .button-26__content
    .button-26__text
      slot
</template>

<script>
export default {
  props: ["enabled"],
  methods: {
    click(event) {
      if (this.enabled) this.$emit("click", event);
    },
  },
};
</script>

<style lang="less" scoped>
.button-26 {
  flex-flow: column;
  display: flex;
  background: #fff;
  border: 2px solid #888;
  font-size: 1.6rem;
  cursor: pointer;
  border-radius: 10px;
  outline: none;
  padding: 0;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.35);
  transition: all 0.1s ease-in-out;
}
.button-26 > * {
  pointer-events: none;
}
.button-26__content {
  flex: 1 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0.4rem 0.7rem;
  border-radius: 8px;
  box-shadow: inset 0 -6px #d5d7de, 0 -2px #ffffff;
  transition: all 0.1s ease-in-out;
  position: static;
}
.button-26__text {
  color: #444;
  display: block;
  transform: translate3d(0, -4px, 0);
  transition: all 0.1s ease-in-out;
  position: static;
  font-size:1.4rem;
}
.button-26:active {
  // box-shadow: none;
  box-shadow: inset 2px 2px 3px #777;
}
.button-26:active .button-26__content {
  box-shadow: none;
}
.button-26:active .button-26__text {
  transform: translate3d(0, 0, 0);
}
.button-26.disabled {
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.35);
  cursor: default;
  opacity: 0.7;
}
.button-26.disabled .button-26__content {
  box-shadow: inset 0 -6px #d5d7de, 0 -2px #ffffff;
}
.button-26.disabled .button-26__text {
  transform: translate3d(0, -4px, 0);
  color: silver;
}
</style>