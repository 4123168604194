<template lang="pug">
.thermostat
  x-switch(:value="boost()", @change="setBoost()")
</template>

<script>
import Vue from 'vue';
import xSwitch from "./Switch.vue";
export default {
  name: "hmipThermostat",
  components: { xSwitch },
  props: ["device"],
  methods: {
    dataPoint(channel, name) {
      console.log("DEVICE", this.device);
      const c =
        this.device && this.device.channel.find((c) => c.index == channel);
      if (c) {
        console.log("CHANNEL", c);
        return c.datapoint.find((d) => d.type == name);
      }
      return null;
    },
    setDataPoint(channel, name, v) {
      const c =
        this.device && this.device.channel.find((c) => c.index == channel);
      if (c) {
    console.log("CHANNEL.1", c.datapoint);
        const datapoint = c.datapoint.map((d) => {
            if (d.type == name) return v;
          return d;
        });
        Vue.set(c, 'datapoint', datapoint);
        console.log("CHANNEL.2", c.datapoint);
      }
    },
    boost() {
      const t = this.dataPoint(1, "BOOST_MODE");
      return t == "true";
    },
    setBoost() {
      let t = this.dataPoint(1, "BOOST_MODE");
      console.log(t);
      this.setDataPoint(1, "BOOST_MODE", { ...t, value: "true" });
    },
  },
};
</script>

<style lang="less" scoped>
</style>