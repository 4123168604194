<template lang="pug">
    .switch(:class="value ? 'active' : ''",@click="toggle()")
        .circle
</template>

<script>
export default {
    props: ['value'],
    methods: {
        toggle() {
            const t = this.value ? 0 : 1;
            this.$emit('change', t)
        }
    }
}
</script>

<style lang="less" scoped>
.switch {
    display:flex;
    justify-content: flex-start;
    flex: 0 0 auto;
    border-radius: 1rem;
    background-color:#ccc;
    width:3rem;
    border:1px solid #666;
    align-items: center;
    cursor:pointer;
}

.switch.active {
    justify-content: flex-end;
}

.circle {
    width:1rem;
    height:1rem;
    background-color: #c00;
    margin:0.2rem;
    border-radius: 50%;
}

.active .circle {
    background-color: #0a0;
}
</style>