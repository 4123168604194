<template lang="pug">
.clock
  digit(v-if="more",:digit="h0",:color="color",:back="back")
  digit(:digit="h1",:color="color",:back="back")
  digit(:digit="h2",:color="color",:back="back")
  div(style="margin: 0.5rem 1rem")
    svg(width="20", height="180", stroke-width="0", viewBox="0,0,20,180")
      path#T1(d="M10,60 L20,70 L10,80 L0,70 L10,60", :fill="color")
      path#T1(d="M10,100 L20,110 L10,120 L0,110 L10,100", :fill="color")
  digit(:digit="m1",:color="color",:back="back")
  digit(:digit="m2",:color="color",:back="back")
  div(style="margin: 0.5rem 1rem")
    svg(width="20", height="180", stroke-width="0", viewBox="0,0,20,180")
      path#T1(d="M10,60 L20,70 L10,80 L0,70 L10,60", :fill="color")
      path#T1(d="M10,100 L20,110 L10,120 L0,110 L10,100", :fill="color")
  digit(:digit="s1",:color="color",:back="back")
  digit(:digit="s2",:color="color",:back="back")
</template>

<script>
import Digit from "./Digit.vue";
import * as moment from "moment";

export default {
  props: ["time","color", "back","more"],
  components: { Digit },
  computed: {
    h0: function () {
        const h = Math.trunc(this.time / 1000 / 60 / 60);
        return Math.trunc(h / 100);
    },
    h1: function () {
        const h = Math.trunc(this.time / 1000 / 60 / 60);
        const t = Math.trunc(h / 10);
        return Math.trunc(h/10) % 10;
    },
    h2: function () {
        const h = Math.trunc(this.time / 1000 / 60 / 60);
        return h % 10;
    },
    m1: function () {
        const t = Math.trunc(this.time / 1000 / 60) % 60;
        return Math.trunc(t / 10);
    },
    m2: function () {
        const t = Math.trunc(this.time / 1000 / 60) % 60;
        return t % 10;
    },
    s1: function () {
        const t = Math.trunc(this.time / 1000) % 60;
        return Math.trunc(t / 10);
    },
    s2: function () {
        const t = Math.trunc(this.time / 1000) % 60;
        return t % 10;
    },
  },
};
</script>

<style lang="less" scoped>
.clock {
    display: flex; 
    zoom: 25%;
}
</style>