<template lang="pug">
    div(style="position:relative;")
        x150
        div(style="padding:16vw 2vw;display:flex;flex-flow:column;align-items:flex-start;position:absolute;left:0; right:0;top:0;bottom:0;")
            Button3d(@click="stats()")
                icon.small(name="chart")
</template>

<script>
import X150 from '@/components/X150.vue';
import Icon from '@/components/Icon.vue';
import Button3d from '@/components/Button3d.vue';

export default {
    components: { X150, Icon, Button3d },
    methods: {
        stats() {
            this.$router.push({ name:'bikestats' });
        }
    }
}
</script>