<template lang="pug">
.grid
  .field
    .cell(
      v-for="i in 50",
      :style="{ backgroundColor: ncolor(i) }"
    ) {{ i }}
      .hitted.noprint(v-if="isDrawed('N', i) && !isCrossed('N', i)")
      .hitted.noprint(v-if="isDrawed('N', i) && isCrossed('N', i)",style="border-color:red;")
      .crossed(v-if="isCrossed('N', i)")
        svg(
          xmlns:xlink="http://www.w3.org/1999/xlink",
          xmlns="http://www.w3.org/2000/svg",
          version="1.1",
          width="100%",
          height="100%",
          viewBox="0 0 48 48"
        )
          g
            path(
              opacity=0.5,
              stroke-width=10,
              stroke="blue",
              d="M 0 0 L 47 47 M 0 47 L 47 0"
            )
  div(style="height: 1vw")
  .sz
    .cell(
        v-for="i in 12", 
    :class="{ drawed: isDrawed('S', i) }", 
    :style="{ backgroundColor: scolor(i) }") {{ i }}
      .hitted.noprint(v-if="isDrawed('S', i) && !isCrossed('S', i)")
      .hitted.noprint(v-if="isDrawed('S', i) && isCrossed('S', i)",style="border-color:red;")
      .crossed(v-if="isCrossed('S', i)")
        svg(
          xmlns:xlink="http://www.w3.org/1999/xlink",
          xmlns="http://www.w3.org/2000/svg",
          version="1.1",
          width="100%",
          height="100%",
          viewBox="0 0 48 48"
        )
          g
            path(
              opacity=0.5,
              stroke-width=10,
              stroke="blue",
              d="M 0 0 L 47 47 M 0 47 L 47 0"
            )
</template>

<script>
export default {
  props: ["crossed", "drawed", "stats"],
  data() {
    return {
      n0: 999999,
      n1: 0,
    };
  },
  mounted() {
    this.n0 = 999999;
    this.n1 = 0;
    this.s0 = 999999;
    this.s1 = 0;
    console.log("minmax", this.n0, this.n1);
  },
  methods: {
    ncolor(n) {
      console.log("COLOR:", n, this.stats);
      if (this.stats && this.stats.nums) {
        if (this.n0 >= 999999) {
          this.stats.nums.forEach((n) => {
            if (n < this.n0) this.n0 = n;
            if (n > this.n1) this.n1 = n;
          });
        }
        const t = this.stats.nums[n - 1];
        const p = 255 - Math.trunc((255 * (t - this.n0)) / (this.n1 - this.n0));
        const c = "#ff" + ("00" + p.toString(16)).slice(-2) + "ff";
        console.log("color:", n, t, p, c, this.n0, this.n1);
        return c;
      }
      return "#88ff88";
    },
    scolor(n) {
      console.log("COLOR:", n, this.stats);
      if (this.stats && this.stats.sz) {
        if (this.s0 >= 999999) {
          this.stats.sz.forEach((n) => {
            if (n < this.s0) this.s0 = n;
            if (n > this.s1) this.s1 = n;
          });
        }
        const t = this.stats.sz[n - 1];
        const p = 255 - Math.trunc((255 * (t - this.s0)) / (this.s1 - this.s0));
        const c = "#" + ("00" + p.toString(16)).slice(-2) + "ffff";
        console.log("color:", n, t, p, c, this.s0, this.s1);
        return c;
      }
      return "#88ff88";
    },
    isCrossed(t, n) {
      if (!this.crossed) return null;
      if (t == "N") return this.crossed.nums.includes(n);
      if (t == "S") return this.crossed.sz.includes(n);
      return false;
    },
    isDrawed(t, n) {
      if (!this.drawed || !this.drawed.drawNumbersCollection) return null;
      console.log(this.drawed.drawNumbersCollection);
      if (t == "N")
        return this.drawed.drawNumbersCollection.find(
          (x) => x.drawNumberType == 0 && x.drawNumber == n
        );
      if (t == "S")
        return this.drawed.drawNumbersCollection.find(
          (x) => x.drawNumberType == 1 && x.drawNumber == n
        );
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.grid {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin: 0;
}
.field {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border: 1px solid gray;
  margin: 0;
  background-color: #eee;
}

.sz {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border: 1px solid gray;
  margin: 0;
  background-color: #fee;
}

.cell {
  border: 1px solid gray;
  width: 8vw;
  height: 8vw;
  margin: 0.3vw;
  line-height: 8vw;
  font-size: 5vw;
  color: black;
  position: relative;
}
.crossed {
  position: absolute;
  left: 10%;
  top: 10%;
  right: 10%;
  bottom: 10%;
}
.drawed {
  background-color: #cfc;
}
.hit {
  background-color: #484;
}
.hitted {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  border: 4px solid green;
  border-radius: 4vw;
}

@media screen and (orientation: landscape) {
    .cell {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.7rem;
    }
}

@media print {
    .noprint {
        display: none;
    }
    .cell {
        width: 5mm;
        height: 5mm;
        line-height: 5mm;
        font-size: 4.5mm;
        background-color:transparent !important;
    }
}
</style>