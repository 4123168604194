<template lang="pug">
    .content
        .person(v-for="p in people")
            div(style="flex: 0 0 auto;display:flex;align-items:center;")
                .title {{p.name}}
                div(style="font-size:3rem;margin: 0 1rem;") {{items[p.name] && items[p.name].count}}
                button3d(style="margin:0.25rem;")
                  icon(name='smoke',@click="addOne(p.name)")
        //- div(v-if="items[p.name] && details == p.name",style="font-size:1.5rem;padding:0 0.5rem;flex:0 0 auto;display:flex;flex-flow:row wrap;overflow:auto;max-height:10rem;")
            div(v-for="x in items[p.name].times",style="padding:0.2rem 0.3rem;") {{time(x.stamp)}}
        //- .details(v-if="details == p.name") 
            .mark(v-for="x in 25",:style="{ left: (x-1)*100/24+'%' }")
            .xaxis
            .point(v-for="d in items[p.name].times",:style="point(d.stamp)")
        //- .details(v-if="details == p.name")
        .chart
            .hour(v-for="h in 24",:style="{ top: (h-1)*100/24+'%' }") {{h-1}}
            .mark(v-for="x in 24",:style="{ top: (x-1)*100/24+'%' }")
            .min(v-for="y in 6",:style="{ left: (y-1)*100/6+'%' }") {{y*10-10}}
            .xaxis(v-for="y in 6",:style="{ left: (y-1)*100/6+'%' }")
            div(v-for="(i,k) in items")
                .point(v-for="d in i.times",:style="point(k, d)")
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import * as moment from 'moment';
import Icon from "@/components/Icon.vue";
import Button3d from '@/components/Button3d.vue';
export default {
    components: { Icon, Button3d },
    data() {
        return {
            details: null,
            people: [
                { name:'Eva', color: 'red', pos: 0 },
                { name:'Gabriel', color: 'blue', pos: 1 }
            ]
        }
    },
    computed: {
        ...mapGetters({
            items: "smoke/items",
        })
    },
    methods: {
        ...mapActions({
            list: 'smoke/list',
            add: 'smoke/add'
        }),
        async addOne(p) {
            await this.add(p);
            this.list();
        },
        time(t) {
            return moment(t).format('HH:mm:ss');
        },
        setDetails(p) {
            this.details = (p && p.name) || null;
        },
        point(name, t) {
            const c = this.people.find(x => x.name == name);
            const m = moment(t.stamp);
            const h = m.hour() + m.minute()/60 + m.second()/3600;
            const y = m.hour() * 100/24 + c.pos*2;
            const x = 100 * (m.minute() + m.second()/60) / 60;
            console.log(name,t,h,x,y,c);
            return {
                // left: 100*h/24 + '%'
                left: x + '%',
                top: y + '%',
                "background-color": c.color 
            }
        }
    },
    mounted() {
        this.list();
    }
}
</script>

<style lang="less" scoped>
    .content {
        flex: 1 0;
        display:flex;
        flex-flow:column;
        flex-flow: row nowrap;
        align-items: stretch;
        // overflow:auto;
        font-size:2rem;
        align-content: flex-start;
    }
    .person {
        flex: 0 0 auto;
        display:flex;

        flex-flow: column nowrap;
        // overflow: auto;
        border: 1px solid silver;
        margin: 0.5rem 1rem;
        border-radius:0.5rem;
        box-shadow: #777 3px 3px 4px;
        color: black;
    }
    .title {
        font-size: 3rem; 
        flex: 1 0;
        text-align: left;
        margin: 0 0.5rem;
    }

    .details {
        display:flex;
        align-self: stretch;
        flex: 0 0 auto;
        position: relative;
        padding: 0;
    }
    .chart {
        margin: 4rem 3rem;
        flex:1 0;
        display: flex;
        position: relative;
        
    }
    .hour {
        position: absolute;
        font-size: 1rem;
        margin-left: -1.5rem;
        margin-top: 0.2rem;
    }
    .min {
        position:absolute;
        margin-left: 0;
        margin-top: -1.2rem;
        font-size: 1rem;
    }
    .xaxis {
        position: absolute;
        // flex:1 0;
        // margin: 1rem 0;
        // padding: 1 rem 0;
        height:100%;
        background-color: #ddd;
        width: 1px;
        // width: 100%;
    }
    .mark {
        position: absolute;
        left: 0;
        right: 0;
        background-color: #ddd;
        height: 1px;
    }
    .point {
        position: absolute;
        width:4px;
        height:2%;
        // background-color: red;
    }
@media (orientation: landscape) {
    .content {
        font-size: 1rem;
    }
    .person {
        width: 36rem;
    }
    .smoke {
        width:3rem;
        height: 3rem;
    }
}
</style>