"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Homematic = void 0;
const api_js_1 = require("@/api.js");
const state = {
    rooms: {},
    devices: {},
    states: {}
};
const mutations = {
    rooms(state, rooms) {
        state.rooms = rooms;
    },
    devices(state, devices) {
        state.devices = devices;
    },
    states(state, states) {
        state.states = states;
    }
};
const getters = {
    rooms: state => state.rooms,
    devices: state => state.devices,
    states: state => state.states
};
const actions = {
    async getRooms({ commit }) {
        const res = await api_js_1.default.homematic.rooms();
        commit('rooms', res.data);
    },
    async getDevices({ commit }) {
        const res = await api_js_1.default.homematic.devices();
        commit('devices', res.data);
    },
    async getStates({ commit }) {
        const res = await api_js_1.default.homematic.states();
        commit('states', res.data);
    },
};
exports.Homematic = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
