import { render, staticRenderFns } from "./TaskEdit.vue?vue&type=template&id=48cd77a2&scoped=true&lang=pug"
import script from "./TaskEdit.vue?vue&type=script&lang=js"
export * from "./TaskEdit.vue?vue&type=script&lang=js"
import style0 from "./TaskEdit.vue?vue&type=style&index=0&id=48cd77a2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48cd77a2",
  null
  
)

export default component.exports