<template lang="pug">
    div(style="margin:0.5rem")
        svg(width="100",height="180",stroke-width="0",viewBox="0,0,100,180")
            path(id="S1",d="M21,0 L79,0 L89,10 L79,20 L21,20 L11,10 L21,0",:fill="s1")
            path(id="S2",d="M100,21 L100,79 L90,89 L80,79 L80,21 L90,11 L100,21",:fill="s2")
            path(id="S3",d="M20,21 L20,79 L10,89 L0,79 L0,21 L10,11 L20,21",:fill="s3")
            path(id="S4",d="M21,80 L79,80 L89,90 L79,100 L21,100 L11,90 L21,80",:fill="s4")
            path(id="S5",d="M100,101 L100,159 L90,169 L80,159 L80,101 L90,91 L100,101",:fill="s5")
            path(id="S6",d="M20,101 L20,159 L10,169 L0,159 L0,101 L10,91 L20,101",:fill="s6")
            path(id="S7",d="M21,160 L79,160 L89,170 L79,180 L21,180 L11,170 L21,160",:fill="s7")
</template>

<script>
export default {
    props: [ 'digit', "color", "back" ],
    data() {
        return {
            colors: [
               [0,2,3,5,6,7,8,9],
               [0,1,2,3,4,7,8,9], 
               [0,4,5,6,8,9], 
               [2,3,4,5,6,8,9], 
               [0,1,3,4,5,6,7,8,9], 
               [0,2,6,8], 
               [0,2,3,5,6,8,9], 
            ]
        }
    },
    computed: {
        s1: function() { return this.segColor(0) },
        s2: function() { return this.segColor(1) },
        s3: function() { return this.segColor(2) },
        s4: function() { return this.segColor(3) },
        s5: function() { return this.segColor(4) },
        s6: function() { return this.segColor(5) },
        s7: function() { return this.segColor(6) },
    },
    mounted() {

    },
    methods: {
        segColor(t) {
            const a = this.colors[t];
            const d = 1 * this.digit;
            const r = a.includes(d) ? (this.color || 'navy') : (this.back || 'transparent');
            return r; 
        }
    }
}
</script>