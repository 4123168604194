<template lang="pug">
.container
  .header Lotto
  .lotto
    .grid
      div(style="font-size: 2.4rem; color: black; align-self: flex-start") Losnummer: {{ coupon.number }}
      lotto-grid(
        v-for="(r, rix) in coupon.fields",
        :checked="r",
        :sz="coupon.sz",
        :draw="draw",
        :id="rix + 1",
        :cfg="{ grid: 1 }"
      )
      div(style="font-size: 1.6rem; color: black") Superzahl {{ coupon.sz }}
    .win
        div(style="font-size:2rem;color:black;text-align:left;") {{ check77() }}
        div(style="font-size:2rem;color:black;text-align:left;") {{ checks6() }}
        lotto-grid(
          v-for="(r, rix) in coupon.fields",
          :checked="r",
          :sz="coupon.sz",
          :draw="draw",
          :id="rix + 1",
          :cfg="{ won: 1 }"
        )
    .panel
      //button3d(style="margin: 1vh 0", @click="play()")
        icon(name="play")
      //button3d(style="margin: 1vh 0", @click="print()")
        icon(name="print")
    //- .freq
      div(style="display: flex; align-items: center", v-for="(f, ix) in freq")
        div(style="width: 2rem; text-align: right; margin-right: 1vh") {{ f.n }}
        .bar(:style="{ width: barwidth(f.f) }") 
          |
          div(style="margin-left: 1rem") {{ f.f }}
    .checked
      div(style="display:flex;")
        icon.button(name="chevronLeft",@click="nextDraw()")
        select(v-model="selectedDate", @change="selectDate(selectedDate)")
          option(v-for="d in dates", :value="d") {{ d.toLocaleDateString("de-DE", dateOpts) }}
        icon.button(name="chevronRight",@click="prevDraw()")
      div(style="display: flex; padding: 1rem 0; align-items: center")
        div(v-for="d in draw.drawNumbersCollection") 
          |
          .ball {{ d.drawNumber }}
          .shadow
        div(
          style="margin: 0 0.2rem; width: 2px; align-self: stretch; background-color: gray"
        )
        div
          .ball {{ draw.superNumber }}
          .shadow
      div(
        style="font-size: 2rem; display: flex; flex-flow: column; align-items: flex-start"
      )
        div(v-if="draw && draw.game77") {{ draw.game77.gameType.name }} {{ draw.game77.numbers }}
        div(v-if="draw && draw.super6") {{ draw.super6.gameType.name }} {{ draw.super6.numbers }}
    //-   div(
    //-     v-for="c in items",
    //-     style="display: flex; margin: 0.25rem 0.5rem; border: 1px solid gray; cursor: pointer;flex:0 0 auto;",
    //-     @click="current = c"
    //-   )
    //-     div(style="padding: 2px 1rem") {{ c.date }}
    //-     div(
    //-       style="padding: 2px 4px; width: 2rem; text-align: right; color: gray",
    //-       v-for="n in c.nums"
    //-     ) {{ n }}
      //- div(style="padding: 2px 2rem") {{ c.a.length }}
      //- div(
      //-   style="padding: 2px 4px; width: 2rem; text-align: right; color: gray",
      //-   :style="{ color: color(c.a.length) }",
      //-   v-for="a in c.a"
      //- ) {{ a }}
    //- div(style="overflow:auto;font-size:1.4vh;")
      div(v-for="i in items") {{i}}
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapActions } from "vuex";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import LottoGrid from "@/components/LottoGrid.vue";

export default {
  components: { Icon, Button3d, LottoGrid },
  data() {
    return {
      dateOpts: {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      selectedDate: 0,
      sum: 0,
      max: 0,
      dates: [],
      freq: {},
      coupon: {
        number: 1971733,
        sz: 3,
        fields: [
          { nums: [2, 3, 10, 12, 14, 20] },
          { nums: [6, 24, 27, 44, 49] },
          { nums: [1, 11, 20, 26, 32, 33] },
          { nums: [9, 22, 25, 30, 40, 42] },
          { nums: [10, 14, 16, 30, 34, 45] },
          { nums: [4, 15, 21, 25, 33, 46] },
        ],
      },
      allItems: [],
      current: { nums: [] },
    };
  },
  computed: {
    ...mapGetters({
      items: "lotto/items",
      draw: "lotto/draw",
    }),
  },
  methods: {
    ...mapActions({
      list: "lotto/list",
      drawing: "lotto/drawing",
    }),
    prevDraw() {
      const f = this.dates.findIndex(x => x == this.selectedDate) + 1;
      if (f >= this.dates.length)
        f = 0;
      this.selectedDate = this.dates[f];
      this.selectDate(this.selectedDate)
    },
    nextDraw() {
      const f = this.dates.findIndex(x => x == this.selectedDate) - 1;
      if (f < 0)
        f = this.dates.length-1;
      this.selectedDate = this.dates[f];
      this.selectDate(this.selectedDate)
    },
    matchFromEnd(str1, str2) {
      console.log(str1, str2);
      let matchLength = 0;
      let i = str1.length - 1;
      let j = str2.length - 1;

      // Iteriere von hinten durch beide Strings
      while (i >= 0 && j >= 0 && str1[i] == str2[j]) {
        matchLength++;
        i--;
        j--;
      }

      return matchLength;
    },
    check77() {
      if (!this.draw || !this.draw.game77)
        return '--';
      const s77 = this.draw.game77.numbers;
      const m = this.matchFromEnd(s77, this.coupon.number+'');
      if (m < 1)
        return '';
      const name = this.draw.game77.gameType.name;  
      if (!this.draw.game77.oddsCollection)
        return name + ': wird ermittelt';
      const won = name + ': ' + this.draw.game77.oddsCollection[7-m].odds + ' €';
      return won;
    },
    checks6() {
      if (!this.draw || !this.draw.super6)
        return '--';      
      const s6 = this.draw.super6.numbers;
      const m = this.matchFromEnd(s6, this.coupon.number+'');
      if (m < 1)
        return '';
      const name = this.draw.super6.gameType.name;
      if (!this.draw.super6.oddsCollection)
        return name + ': wird ermittelt';
      const won = name + ': ' +this.draw.super6.oddsCollection[6-m].odds + ' €';
      return won;
    },
    selectDate(d) {
      this.getDrawing(d);
    },
    color(n) {
      const c = ["", "", "#ccc", "#c86", "#864", "#642", "#420"];
      console.log("COLOR", n, c[n]);
      return c[n];
    },
    print() {
      window.print();
    },
    barwidth(n) {
      return (200 * n) / this.max + "px";
    },
    check(sel) {
      const found = [];
      this.items.forEach((t) => {
        const a = t.nums.filter((value) => sel.nums.includes(value));
        if (a.length > 2) {
          found.push({ ...t, a });
        }
      });
      found.sort((a, b) => {
        const ad = moment(a.date, "DD.MM.YYYY");
        const bd = moment(b.date, "DD.MM.YYYY");
        return ad.isBefore(bd) ? 1 : -1;
      });
      this.checked = found;
    },
    play() {
      let runs = 0;
      const s = { nums: [] };
      while (s.nums.length < 6 && runs < 10) {
        runs++;
        const r = Math.trunc(Math.random() * this.sum);
        const o = this.freq.find((f) => f.s > r);
        // console.log(runs, r, o);
        // while (p < r) {
        //     p += this.freq[i].f;
        //     n = 1*this.freq[i].n;
        //     i++;
        // }
        // console.log(r,p,n,i);
        if (!s.nums.includes(o.n)) s.nums.push(o.n);
      }
      this.selected[0] = s;
      this.check(s);
    },
    crossed(n) {
      if (this.selected.nums.includes(n)) return "#ff0000";
      return 0;
    },
    generateDates(startYear) {
      const today = new Date(); // Heutiges Datum
      const result = [];
      let currentDate = new Date(startYear, 0, 1); // 1. Januar des Startjahres

      // Finde den ersten Mittwoch oder Samstag ab dem Startdatum
      while (currentDate.getDay() !== 3 && currentDate.getDay() !== 6) {
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Springe zwischen Mittwoch und Samstag
      while (currentDate <= today) {
        result.push(new Date(currentDate)); // Datum speichern

        // Nächster Mittwoch (+3 Tage) oder Samstag (+4 Tage)
        currentDate.setDate(
          currentDate.getDate() + (currentDate.getDay() === 3 ? 3 : 4)
        );
      }

      result.sort((a, b) => {
        return b.getTime() - a.getTime();
      });
      return result;
    },
    async getDrawing(date) {
      await this.drawing(date);
      const d = this.draw;
      this.current = {
        sz: d.superNumber,
        nums: d.drawNumbersCollection.map((x) => x.drawNumber),
      };
    },
  },
  async mounted() {
    this.dates = this.generateDates(2018);
    this.selectedDate = this.dates[0];
    this.getDrawing(this.selectedDate);
    await this.list();
    this.items.sort((a, b) => {
      const ad = moment(a.date, "DD.MM.YYYY");
      const bd = moment(b.date, "DD.MM.YYYY");
      //   console.log(ad, bd, bd.isBefore(ad));
      return ad.isBefore(bd) ? 1 : -1;
    });
    this.freq = [];
    this.sum = 0;
    this.max = 0;
    const f = {};
    this.items.forEach((t) => {
      this.sum += 6;
      t.nums.forEach((n) => {
        f[n] = (f[n] || 0) + 1;
      });
    });
    const t = Object.keys(f).map((key) => {
      return { n: 1 * key, f: f[key] };
    });
    t.sort((a, b) => b.f - a.f);
    let s = 0;
    t.forEach((x) => {
      if (x.f > this.max) this.max = x.f;
      s += x.f;
      x.s = s;
    });
    this.freq = t;

  },
};
</script>

<style lang="less" scoped>
.container {
  font-size: 4vh;
  overflow: auto;
  flex: 1 0;
  display: flex;
  flex-flow: column;
}
.lotto {
  display: flex;
  flex: 1 0;
  justify-items: flex-start;
  align-self: center;
  overflow: auto;
}
.grid {
  flex: 1 0;
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: #fbddbf;
  padding: 1rem 0;
}
.freq {
  padding: 0 4vh;
  display: flex;
  flex-flow: column;
  margin: 0 4vh;
  flex: 0 0 auto;
  overflow: auto;
  font-size: 1.4vh;
  color: black;
  align-self: stretch;
  align-items: flex-start;
}
.bar {
  // height:20px;
  text-align: left;
  margin: 2px 0;
  padding: 4px 0;
  background-color: #88cc88;
  flex: 0 0 auto;
}
.checked {
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
  overflow: auto;
  font-size: 1.8vh;
  color: black;
  overflow: auto;
  padding: 1vw;
  margin: 0;
  overflow: hidden;
  border:1px solid gray;
}
.ball {
  font-size: 3rem;
  height: 5rem;
  width: 5rem;
  line-height: 5rem;
  // border: 1px solid black;
  border-radius: 5rem;
  margin: 0 0.5rem;
  // position: relative;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(80, 80, 80, 1) 70%,
    rgba(80, 80, 80, 1) 100%
  );
  //box-shadow: 4px 4px 5px #777;
  z-index: 1;
}
.shadow {
  z-index: 0;
  // position:absolute;
  margin-left: 0.5rem;
  margin-top: -7%;
  // top:70%;
  // left: 0;
  // right: 0;
  // bottom: 0;
  background: radial-gradient(#000f, #fff0 72%);
  height: 1rem;
  width: 5rem;
  border-radius: 50%;
}
.win {
  flex:0 0 auto;
  display:flex;
  flex-flow:column;
  background-color:#efe;
  padding:1rem;
  margin:1rem 0;
  align-self: stretch;
}
@media screen and (orientation: portrait) {
  .header {
    font-size: 6vw;
    color: #26a;
  }
  .drawDate {
    font-size: 6vw;
  }
  select {
    font-size: 5.5vw;
    margin: 0 1vw;
  }
  .lotto {
    display: flex;
    align-items: center;
    overflow: auto;
    flex-flow: column;
    flex-direction: column-reverse;
  }
  .grid {
    flex: 1 0;
    overflow: auto;
    grid-template-columns: 1fr;
    align-self: stretch;
  }
  .ball {
    height: 10vw;
    width: 10vw;
    line-height: 10vw;
    border-radius: 50%;
    margin: 0 0.5vw;
    font-size: 5vw;
    font-weight: bold;
  }
  .shadow {
    width: 10vw;
  }
}
@media print {
  .panel,
  .freq {
    display: none;
  }
  .ball {
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.5rem;
    border: 1px solid black;
    border-radius: 1rem;
    margin: 0 0.2rem;
  }
}
</style>