<template lang="pug">
.task
  h2 {{ header }}
  .taskBody
    labeled(caption="Projekt")
      select(v-model="selected", style="width: 30rem")
        option(v-for="p in projects", :value="p.id") {{ p.name }}
    div {{ task }}
  .footer
    button3d1(enabled="true")
      icon(name="close")
    button3d1(enabled="true")
      icon(name="save")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Icon from "@/components/Icon.vue";
import Button3d1 from "@/components/Button3d1.vue";
import Labeled from '@/components/Labeled.vue';
export default {
  props: ["header", "task"],
  components: { Icon, Button3d1, Labeled },
  data() {
    return {
      selected: null,
    };
  },
  async mounted() {
    await this.getProjects();
  },
  computed: {
    ...mapGetters({
      projects: "worktime/projects",
    }),
  },
  methods: {
    ...mapActions({
      getProjects: "worktime/getProjects",
    }),
  },
};
</script>

<style lang="less" scoped>
.task {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 4px 4px 6px #000;
  padding: 1rem;
  color: black;
}
.taskBody {
  margin: 1rem 0;
  padding: 1rem 0;
  border-bottom: 1px solid silver;
}
.footer {
  align-self: stretch;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-evenly;
}
</style>