<template lang="pug">
.navi
  .item(v-for="m in menu",@click="goto(m.target)")
    icon(:name="m.icon")
    .name {{ m.name }}
</template>

<script>
import Icon from "@/components/Icon.vue";
export default {
  components: { Icon },
  data() {
    return {
      menu: [
        { icon: "project", name: "Projekte" },
        { icon: "tasks", name: "Aufgaben", target: "tasks" },
        { icon: "tasklist", name: "Aufgabenliste" },
        { icon: "newbill", name: "Neue Rechnung" },
        { icon: "bills", name: "Rechnungen" },
        { icon: "calendar", name: "Kalender" },
      ],
    };
  },
  methods: {
    goto(target) {
      this.$router.push({ name: target }).catch((err) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.navi {
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
  color: white;
  padding: 0.7rem;
}
.item {
    display: flex;
    margin: 0.3rem 0;
    cursor: pointer;
    align-items: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: #468;
    padding:0.5rem;
    box-shadow: 2px 2px 4px #000;
}
.name {
    margin-left: 0.5rem;
}
</style>