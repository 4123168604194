<template lang="pug">
.eurojackpot
  .drawing.noprint
    div(style="display: flex")
      icon.button(name="chevronLeft", @click="nextDraw()")
      select(v-model="selectedDate", @change="selectDate(selectedDate)")
        option(v-for="d in dates", :value="d") {{ d.toLocaleDateString("de-DE", dateOpts) }}
      icon.button(name="chevronRight", @click="prevDraw()")
      icon.small.button(name="print", @click="print()")
    div(
      v-if="draw && draw.drawNumbersCollection",
      style="display: flex; padding: 1rem 0; align-items: center"
    )
      div(
        v-for="d in draw.drawNumbersCollection.filter((x) => x.drawNumberType == 0)"
      ) 
        .ball {{ d.drawNumber }}
        .shadow
      div(
        style="margin: 0 0.2rem; width: 2px; align-self: stretch; background-color: gray"
      )
      div(
        v-for="d in draw.drawNumbersCollection.filter((x) => x.drawNumberType == 1)"
      )
        .ball {{ d.drawNumber }}
        .shadow
  .quotes
  .fields
    .field(v-for="f in coupon.fields")
      EurojackpotGrid(:crossed="f.crossed", :drawed="draw", :stats="stats")
      button3d.noprint(v-if="!f.readonly")
        icon.small(name="play", @click="play(f)")
    button3d.noprint
      icon.small(name="add", @click="add()")
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapActions } from "vuex";
import api from "@/api";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import EurojackpotGrid from "@/components/EurojackpotGrid.vue";
import Vue from "vue";

export default {
  components: { Icon, Button3d, EurojackpotGrid },
  data() {
    return {
      dateOpts: {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      stats: {},
      dates: [],
      selectedDate: null,
      coupon: {
        fields: [
          {
            crossed: { nums: [14, 22, 28, 44, 46], sz: [7, 10] },
            readonly: true,
          },
          { crossed: { nums: [6, 7, 23, 26, 42], sz: [5, 7] }, readonly: true },
        ],
      },
      drawed: null,
    };
  },
  async mounted() {
    // this.coupon.fields = [{}];
    const t = await this.list();
    this.stats = this.items.stats;
    this.dates = this.generateDates(2022);
    this.selectedDate = this.dates[0];
    await this.drawing(this.selectedDate.getTime());
  },
  computed: {
    ...mapGetters({
      items: "eurojackpot/items",
      draw: "eurojackpot/draw",
    }),
  },
  methods: {
    ...mapActions({
      list: "eurojackpot/list",
      drawing: "eurojackpot/drawing",
    }),
    print() {
      window.print();
    },
    async selectDate(d) {
      await this.drawing(d.getTime());
    },
    prevDraw() {
      const f = this.dates.findIndex((x) => x == this.selectedDate) + 1;
      if (f >= this.dates.length) f = 0;
      this.selectedDate = this.dates[f];
      this.selectDate(this.selectedDate);
    },
    nextDraw() {
      const f = this.dates.findIndex((x) => x == this.selectedDate) - 1;
      if (f < 0) f = this.dates.length - 1;
      this.selectedDate = this.dates[f];
      this.selectDate(this.selectedDate);
    },
    add() {
      this.coupon.fields.push({});
    },
    async play(f) {
      console.log("PLAY", f);
      const resp = await api.eurojackpot.random();
      Vue.set(f, "crossed", resp.data);
      // f.crossed = resp.data;
      console.log(f);
    },
    generateDates(startYear) {
      const today = new Date(); // Heutiges Datum
      const result = [];
      let currentDate = new Date(Date.UTC(startYear, 0, 1)); // 1. Januar des Startjahres

      // Finde den ersten Mittwoch oder Samstag ab dem Startdatum
      while (currentDate.getDay() !== 2 && currentDate.getDay() !== 5) {
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
      }

      // Springe zwischen Mittwoch und Samstag
      while (currentDate <= today) {
        result.push(new Date(currentDate)); // Datum speichern

        // Nächster Dienstag (+3 Tage) oder Freitag (+4 Tage)
        currentDate.setUTCDate(
          currentDate.getUTCDate() + (currentDate.getUTCDay() === 2 ? 3 : 4)
        );
      }

      result.sort((a, b) => {
        return b.getTime() - a.getTime();
      });

      return result;
    },
  },
};
</script>

<style lang="less" scoped>
.eurojackpot {
  display: flex;
}
.drawing {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.ball {
  font-size: 3rem;
  color: black;
  height: 5rem;
  width: 5rem;
  line-height: 5rem;
  // border: 1px solid black;
  border-radius: 5rem;
  margin: 0 0.5rem;
  // position: relative;
  background: radial-gradient(
    circle at 30% 30%,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(80, 80, 80, 1) 70%,
    rgba(80, 80, 80, 1) 100%
  );
  //box-shadow: 4px 4px 5px #777;
  z-index: 1;
}
.shadow {
  z-index: 0;
  // position:absolute;
  margin-left: 0.5rem;
  margin-top: -7%;
  // top:70%;
  // left: 0;
  // right: 0;
  // bottom: 0;
  background: radial-gradient(#000f, #fff0 72%);
  height: 1rem;
  width: 5rem;
  border-radius: 50%;
}
.field {
    margin: 1rem;
}
.fields {
    display:flex;
    flex-flow: row wrap;
}
@media screen and (orientation: portrait) {
  .eurojackpot {
    flex-flow: column;
    flex: 1 0;
    overflow: auto;
  }
  .fields {
    flex:1 0;
    overflow: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .field {
    margin-bottom: 4vw;
    padding: 2vw;
    display: flex;
    align-items: flex-start;
  }
  .button {
    margin: 0 2vw;
    padding: 1vw;
    color: black;
  }
  .ball {
    height: 10vw;
    width: 10vw;
    line-height: 10vw;
    border-radius: 50%;
    margin: 0 0.5vw;
    font-size: 5vw;
    font-weight: bold;
  }
  .shadow {
    width: 10vw;
  }
}
@media print {
    .fields {
        flex-flow: row wrap;
    }
    .noprint {
        display:none;
    }
}
</style>