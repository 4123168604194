"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const axios_1 = require("axios");
const a = axios_1.default.create({
    // baseURL: `http://192.168.178.20:9003/gg`
    baseURL: `https://gnatowski.ddns.net`
});
const s = axios_1.default.create({
    baseURL: `http://192.168.178.55:8087`
});
const w = axios_1.default.create({
    baseURL: `http://192.168.178.21/addons/xmlapi/`
});
exports.default = {
    // user: {
    //     login: (username, password) => a.post('/api/login', { username, password })
    // },
    tools: {
        kfz: () => a.get(`/api/kfz`),
        smoked: () => a.get(`/api/smoked/today`),
        smokedOn: d => a.get(`/api/smoked/${d}`),
        smoke: person => a.post(`/api/smoke`, { person }),
        lotto: () => a.get(`/api/lotto`)
    },
    stairs: {
        effects: () => s.get('/api/effects'),
        clear: () => s.post('/api/clear'),
        lower: (id) => s.get(`/api/distance/lower`),
        upper: (id) => s.get(`/api/distance/upper`),
        effect: (effect) => s.post(`/api/effect/${effect}`),
        color: (c) => { const t = `/api/color/${c.replace('#', '')}`; s.post(t); console.log('api.color', c, t); },
        play: (dir) => s.post(`/api/play/${dir}`),
        copy: (data) => s.post(`/api/copy`, data),
    },
    media: {
        files: (filter, page) => a.get(`/api/media/files`, { params: { page, ...(filter || {}) } }),
        item: (id) => a.get(`/api/media/item/${id}`),
        content: (id) => a.get(`/api/media/content/${id}`, { responseType: 'arraybuffer' })
    },
    bike: {
        add: data => a.post(`/api/bike`, data),
        list: config => a.get(`/api/bike?${Object.keys(config).map(k => k + '=' + config[k]).join('&')}`)
    },
    lotto: {
        drawing: ut => a.get(`/api/lotto/drawing/${ut}`)
    },
    eurojackpot: {
        list: () => a.get(`/api/eurojackpot`),
        drawing: ut => a.get(`/api/eurojackpot/${ut}`),
        random: () => a.get(`/api/eurojackpot/random`)
    },
    worktime: {
        projects: () => a.get('/api/worktime/projects'),
        tasks: params => a.get('/api/worktime/tasks', { params }),
        getTimer: timerId => a.get(`/api/worktime/timer/${timerId}`),
        stopTimer: timerId => a.put(`/api/worktime/stop/${timerId}`),
        startTimer: taskId => a.post(`/api/worktime/start/${taskId}`)
    },
    homematic: {
        rooms: () => a.get(`/api/homematic/rooms`),
        devices: () => a.get(`/api/homematic/devices`),
        states: () => a.get(`/api/homematic/states`),
    }
};
