<template lang="pug">
.tasks
  task(v-for="t in tasks", :task="t")
  button3d1(style="justify-self:flex-start;",enabled="1")
    icon(name="add")
  .blocker
    task-edit(:header="taskHeader")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Task from "@/components/worktime/Task.vue";
import TaskEdit from "@/components/worktime/TaskEdit.vue"
import Icon from "@/components/Icon.vue";
import Button3d1 from "@/components/Button3d1.vue";

export default {
  components: { Icon, Task, TaskEdit, Button3d1 },
  data() {
    return {
        taskHeader: 'Neuer Task'
    }
  },
  async mounted() {
    await this.getTasks({ status: 0, order: "id asc" });
  },
  computed: {
    ...mapGetters({
      tasks: "worktime/tasks",
    }),
  },
  methods: {
    ...mapActions({
      getTasks: "worktime/getTasks",
    }),
  },
};
</script>

<style lang="less" scoped>
.tasks {
    position: relative;
    margin: 1vw;
    flex:1 0;
    align-items: flex-start;
    // display:flex;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: max-content;
    gap: 1rem;
    overflow:auto;
    padding: 1rem 1rem;
}
.blocker {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.9;
    display:flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
</style>