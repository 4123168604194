<template lang="pug">
div
  h2 Homematic
  hmip-thermostat(:device="states['3261']")
  div {{ rooms }}
  div {{ states }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import hmipThermostat from "@/components/HmIP-eTRV-B-2_R4M.vue";

export default {
  components: { hmipThermostat },
  data() {
    return {
      server: null,
      client: null,
      ccuIp: "192.168.178.20",
      ccuPort: 2001,
    };
  },
  computed: {
    ...mapGetters({
      rooms: "homematic/rooms",
      devices: "homematic/devices",
      states: "homematic/states",
    }),
  },
  async created() {

  },
  async mounted() {
    await this.getRooms();
    await this.getDevices();
    await this.getStates();
    console.log("STATE", this.states["3261"]);
  },
  methods: {
    ...mapActions({
      getRooms: "homematic/getRooms",
      getDevices: "homematic/getDevices",
      getStates: "homematic/getStates",
    }),

    unsubscribe() {
      this.client.methodCall(
        "init",
        [`http://${this.ccuIp}.34:${this.ccuPort}`, ""],
        (err, res) => {}
      );
    },

    subscribe() {
      this.client.methodCall(
        "init",
        [`http://${this.ccuIp}.34:${this.ccuPort}`, "hmSmart"],
        function (err, res) {
          console.log(err, res);
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
</style>