"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Worktime = void 0;
const vue_1 = require("vue");
const api_js_1 = require("@/api.js");
const state = {
    projects: [],
    tasks: {}
};
const mutations = {
    projects(state, projects) {
        state.projects = projects;
    },
    tasks(state, tasks) {
        state.tasks = tasks;
    },
    updateTimer(state, timer) {
        const taskIndex = state.tasks.findIndex(t => t.id == timer.taskId);
        if (taskIndex >= 0) {
            const task = state.tasks[taskIndex];
            const timeIndex = task.times.findIndex(time => time.id == timer.id);
            if (timeIndex >= 0) {
                vue_1.default.set(task.times, timeIndex, timer); // Ersetzen für Reaktivität
            }
            else {
                task.times = [...task.times, timer]; // Neuzuweisung erzwingen
            }
            vue_1.default.set(state.tasks, taskIndex, { ...task }); // Reaktivität erzwingen
        }
    }
};
const getters = {
    projects: state => state.projects,
    tasks: (state) => state.tasks
};
const actions = {
    async getProjects({ commit }) {
        const res = await api_js_1.default.worktime.projects();
        commit('projects', res.data);
    },
    async getTasks({ commit }, params) {
        const res = await api_js_1.default.worktime.tasks(params);
        commit('tasks', res.data);
    },
    async getTimer({ commit }, timerId) {
        const timer = await api_js_1.default.worktime.getTimer(timerId);
        commit('updateTimer', timer.data);
    },
    async startTimer({ commit }, taskId) {
        const timer = await api_js_1.default.worktime.startTimer(taskId);
        commit('updateTimer', timer.data);
    },
    async stopTimer({ commit }, timerId) {
        const timer = await api_js_1.default.worktime.stopTimer(timerId);
        commit('updateTimer', timer.data);
    }
};
exports.Worktime = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
