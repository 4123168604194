<template lang="pug">
.task(:class="{ running: running }")
  .title {{ task.title }}
  .timer
    clock(:time="time", style="flex: 1 0; justify-content: center")
    div(
      style="flex: 0 0 auto; display: flex; justify-content: flex-end; align-items: center"
    )
      button3d1(
        :enabled="!running && task.status < 1",
        @click="start(task.id)"
      )
        icon.tiny(name="play")
      button3d1(
        :enabled="running && task.status < 1",
        @click="stop(running.id)"
      )
        icon.tiny(name="stop")
    .billInfo 
      |
      |
      .bt {{ billTime }} h
      .bv {{ billValue }}
  .body
    .container
      .desc
        div {{ task.text }}
        div {{ task.solution }}
      .iconblock
        button3d1(
          style="margin: 0.2rem 0",
          :enabled="!running && task.status < 1"
        )
          icon.small(name="eye")
        button3d1(
          style="margin: 0.2rem 0",
          :enabled="!running && task.status < 1"
        )
          icon.small(name="pencil")
        button3d1(
          style="margin: 0.2rem 0",
          :enabled="!running && task.status < 1"
        )
          icon.small(name="ok")
        button3d1(
          style="margin: 0.2rem 0",
          :enabled="!running && task.status < 1"
        )
          icon.small(name="trash")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Clock from "@/components/Clock.vue";
import Icon from "@/components/Icon.vue";
import Button3d1 from "@/components/Button3d1.vue";
export default {
  components: { Clock, Icon, Button3d1 },
  props: ["task"],
  data() {
    return {
      part: 0.25,
      //   running: null,
      worktime: 0,
      time: 0,
      timer: null,
    };
  },
  mounted() {
    this.init();
    this.timer = window.setInterval(() => {
      this.time = this.getTime();
    }, 1000);
  },
  beforeUnmount() {
    this.timer && window.clearInterval(this.timer);
  },
  computed: {
    running() {
      return this.task.times.find((time) => time.status < 1) || null;
    },
    billTime() {
      const t = this.time / 1000 / 60;
      const d =
        this.part *
        Math.trunc((t + (60 * this.part - 0.001)) / (this.part * 60));
      return d.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    billValue() {
      const w = this.task.times;
      console.log('task.time', w);
      let v = 0;
      if (w && w.length > 0) {
        const p = (w[0].price || 4500) / 100;
        const t = this.time / 1000 / 60;
        const d =
          this.part *
          Math.trunc((t + (60 * this.part - 0.001)) / (this.part * 60));
        v = p * d;
      }
      console.log('billValue:', v);
      return v.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
    },
  },
  methods: {
    ...mapActions({
      startTimer: "worktime/startTimer",
      stopTimer: "worktime/stopTimer",
    }),
    init() {
      const t = this.task.times.reduce((p, c) => {
        const d = c.status ? c.duration || 0 : 0;
        return p + d;
      }, 0);
      this.worktime = t || 0;
      this.time = this.getTime();
      //   const r = this.task.times.find((x) => x.status < 1);
      //   this.running = r || null;
    },
    async start(taskId) {
      await this.startTimer(taskId);
      this.init();
    },
    async stop(timerId) {
      await this.stopTimer(timerId);
      this.init();
    },
    getTime() {
      const d = new Date();
      const t =
        this.worktime + (this.running ? d.getTime() - this.running.start : 0);
      return t;
    },
  },
};
</script>

<style lang="less" scoped>
.task {
  color: black;
  border: 1px solid gray;
  border-radius: 10px;
  box-shadow: 4px 4px 6px #666;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}
.running {
  background-color: #cfc;
}
.title {
  flex: 0 0 auto;
  font-size: 1.2rem;
  align-self: stretch;
  background-color: #bdf;
  padding: 0.2rem;
  text-align: left;
}
.body {
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
  padding: 0.5rem;
  height: 12rem;
  overflow: auto;
}
.timer {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2rem;
}
.key {
  margin: 0 0.2rem;
}
.container {
  flex: 1 0;
  display: flex;
  overflow: auto;
}
.desc {
  flex: 1 0;
  display: flex;
  flex-flow: column;
  overflow: auto;
}
.iconblock {
  flex: 0 0 auto;
  border-left: 1px solid gray;
  padding: 0 0.5rem;
}
.billInfo {
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  font-size: 1.2rem;
  margin: 0 0.5rem;
}
.clock {
  zoom: 18%;
}
</style>