<template lang="pug">
    .labeled
        .title {{label}}
        slot
</template>

<script>
export default {
    props: ['label']
}
</script>

<style lang="less" scoped>
@import (reference) '../shared.less';
.labeled {
    display:flex;
    flex-flow:column;
    align-items: flex-start;
    margin-top: 1rem;
}
.title {
    font-size: 1rem;
    color:gray;
}

</style>