"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const App_vue_1 = require("./App.vue");
require("./registerServiceWorker");
const vue_meta_1 = require("vue-meta");
const router_1 = require("./router");
const store_1 = require("./store");
// import * as VueGoogleMaps from 'vue2-google-maps';
vue_1.default.config.productionTip = false;
vue_1.default.config.devtools = true;
vue_1.default.use(vue_meta_1.default);
console.log("Vue Version " + vue_1.default.version);
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAEEH53yxdezxl76pAajIcsM56ducvz0mA',
//     libraries: 'places',
//   }
// });
new vue_1.default({
    router: router_1.default,
    store: store_1.default,
    render: h => h(App_vue_1.default)
}).$mount('#app');
