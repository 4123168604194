"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_router_1 = require("vue-router");
const vue_meta_1 = require("vue-meta");
const Home_vue_1 = require("../views/Home.vue");
const About_vue_1 = require("@/views/About.vue");
const Impressum_vue_1 = require("@/views/Impressum.vue");
const Contact_vue_1 = require("@/views/Contact.vue");
const Demos_vue_1 = require("@/views/Demos.vue");
const Tools_vue_1 = require("@/views/Tools.vue");
const Kfz_vue_1 = require("@/views/Kfz.vue");
const Kfz2_vue_1 = require("@/components/Kfz2.vue");
const Rubik_vue_1 = require("@/views/Rubik.vue");
const Piano_vue_1 = require("@/components/Piano.vue");
const Pentomino_vue_1 = require("@/components/Pentomino.vue");
const Towers_vue_1 = require("@/components/Towers.vue");
const Smoke_vue_1 = require("@/views/Smoke.vue");
const Sokoban_vue_1 = require("@/components/Sokoban.vue");
const Stairs_vue_1 = require("@/components/Stairs.vue");
const ChessView_vue_1 = require("@/views/ChessView.vue");
const TetrisView_vue_1 = require("@/views/TetrisView.vue");
const Media_vue_1 = require("@/views/Media.vue");
const IconList_vue_1 = require("@/views/IconList.vue");
const Mrt_vue_1 = require("@/components/Mrt.vue");
const RecipesView_vue_1 = require("@/views/RecipesView.vue");
const Recipe_vue_1 = require("@/components/Recipe.vue");
const RecipePrint_vue_1 = require("@/components/RecipePrint.vue");
const Lotto_vue_1 = require("@/views/Lotto.vue");
const Bike_vue_1 = require("@/views/Bike.vue");
const BikeStats_vue_1 = require("@/views/BikeStats.vue");
const EurojackpotView_vue_1 = require("@/views/EurojackpotView.vue");
const WorktimeView_vue_1 = require("@/views/Worktime/WorktimeView.vue");
const TasksView_vue_1 = require("@/views/Worktime/TasksView.vue");
const HomematicView_vue_1 = require("@/views/Homematic/HomematicView.vue");
vue_1.default.use(vue_router_1.default);
vue_1.default.use(vue_meta_1.default);
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home_vue_1.default,
    },
    {
        path: '/about',
        name: 'about',
        component: About_vue_1.default
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: Impressum_vue_1.default
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact_vue_1.default
    },
    {
        path: '/demos',
        name: 'demos',
        component: Demos_vue_1.default,
        children: [
            // {
            //   path: '',
            //   // name: 'demos',
            //   component: DemoList
            // },
            {
                path: 'rubik',
                name: 'rubik',
                component: Rubik_vue_1.default
            },
            {
                path: 'piano',
                name: 'piano',
                component: Piano_vue_1.default
            },
            {
                path: 'pentomino',
                name: 'pentomino',
                component: Pentomino_vue_1.default
            },
            {
                path: 'towers',
                name: 'towers',
                component: Towers_vue_1.default
            },
            {
                path: 'sokoban',
                name: 'sokoban',
                component: Sokoban_vue_1.default
            },
            {
                path: 'chess',
                name: 'chess',
                component: ChessView_vue_1.default
            },
            {
                path: 'tetris',
                name: 'tetris',
                component: TetrisView_vue_1.default
            },
        ]
    },
    {
        path: '/tools',
        name: 'tools',
        component: Tools_vue_1.default,
        children: [
            {
                path: 'bike',
                name: 'bike',
                component: Bike_vue_1.default
            },
            {
                path: 'bikestats',
                name: 'bikestats',
                component: BikeStats_vue_1.default
            },
            {
                path: 'lotto',
                name: 'lotto',
                component: Lotto_vue_1.default
            },
            {
                path: 'eurojackpot',
                name: 'eurojackpot',
                component: EurojackpotView_vue_1.default
            },
            {
                path: 'kfz2',
                name: 'kfz2',
                component: Kfz2_vue_1.default
            },
            {
                path: 'kfz',
                name: 'kfz',
                component: Kfz_vue_1.default
            },
            {
                path: 'smoke',
                name: 'smoke',
                component: Smoke_vue_1.default
            },
            {
                path: 'media',
                name: 'media',
                component: Media_vue_1.default
            },
            {
                path: 'stairs',
                name: 'stairs',
                component: Stairs_vue_1.default
            },
            {
                path: 'icons',
                name: 'icons',
                component: IconList_vue_1.default
            },
            {
                path: 'mrt',
                name: 'mrt',
                component: Mrt_vue_1.default
            },
            {
                path: 'recipes',
                name: 'recipes',
                component: RecipesView_vue_1.default
            },
            {
                path: 'recipe/:id',
                name: 'recipe',
                component: Recipe_vue_1.default
            },
            {
                path: 'recipePrint/:id',
                name: 'recipePrint',
                component: RecipePrint_vue_1.default
            }
        ]
    },
    {
        path: '/worktime',
        name: 'worktime',
        component: WorktimeView_vue_1.default,
        children: [
            {
                path: 'tasks',
                name: 'tasks',
                component: TasksView_vue_1.default
            }
        ]
    },
    {
        path: '/homematic',
        name: 'homematic',
        component: HomematicView_vue_1.default,
    }
];
const router = new vue_router_1.default({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
exports.default = router;
